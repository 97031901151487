exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-templates-mblist-js": () => import("./../../../src/templates/mblist.js" /* webpackChunkName: "component---src-templates-mblist-js" */),
  "component---src-templates-mbpost-js": () => import("./../../../src/templates/mbpost.js" /* webpackChunkName: "component---src-templates-mbpost-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-postlist-js": () => import("./../../../src/templates/postlist.js" /* webpackChunkName: "component---src-templates-postlist-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-publications-js": () => import("./../../../src/templates/publications.js" /* webpackChunkName: "component---src-templates-publications-js" */)
}

